import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { MDContent } from "../components/Content";

export const MortgageGlossaryOfTermsPageTemplate = ({
  title,
  image,
  terms,
  helmet,
}) => {
  return (
    <section>
      {helmet || ""}
      <div className="content">
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url(${
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
            })`,
            backgroundPosition: `center`,
            minHeight: 500,
          }}
        >
          <h2 className="has-text-weight-bold has-text-white is-size-1">
            {title}
          </h2>
        </div>
      </div>
      <div className="section">
        <div className="container" style={{ maxWidth: "40rem" }}>
          <h5 className="title is-5">{title}</h5>
          {Array.isArray(terms)
            ? terms.map((term, idx) => (
                <div key={term.title} id={term.title} className="mt-6">
                  <h6 className="title is-6">{term.title}</h6>
                  <div className="mt-1">
                    <MDContent content={term.text} />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </section>
  );
};

MortgageGlossaryOfTermsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  helmet: PropTypes.object,
};

const MortgageGlossaryOfTermsPage = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout>
      <MortgageGlossaryOfTermsPageTemplate
        helmet={
          <Helmet titleTemplate="Glossary Of Terms | Get Started">
            <title>{`${markdownRemark.frontmatter.title}`}</title>
            <meta name="description" content={`Glossary Of Terms`} />
          </Helmet>
        }
        title={markdownRemark.frontmatter.title}
        image={markdownRemark.frontmatter.image}
        terms={markdownRemark.frontmatter.terms}
      />
    </Layout>
  );
};

MortgageGlossaryOfTermsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default MortgageGlossaryOfTermsPage;

export const MortgageGlossaryOfTermsPageQuery = graphql`
  query MortgageGlossaryOfTermsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        terms {
          title
          text
        }
      }
    }
  }
`;
